<template>
  <v-container id="container-div" class="overflow-hidden">
    <v-menu max-width="100%" min-width="100%" v-model="menu" :close-on-content-click="false" offset-x :key="reRenderNotes" class="notes">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :class="['elevation-0 mr-3 notes-wrapper', $vuetify.breakpoint.xs ? 'notes-wrapper--xs' : $vuetify.breakpoint.xs ? 'notes-wrapper--xs' : '' ]"  fab fixed right v-bind="attrs" small v-on="on" :color="listOfNotes.length > 0 ? (systemDetails.themecolor || 'info') : ''">
          <v-icon class="pr-5">mdi-clipboard-text-multiple-outline  </v-icon>
          {{ `(${listOfNotes.length})` }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar :color="systemDetails.themecolor || 'light-blue'" light height="50px">
          <v-toolbar-title :style="{ color: systemDetails.textcolor || 'white' }" class="mr-2">
            <v-icon class="mr-0" :color="systemDetails.textcolor || 'white'">mdi-calendar-text</v-icon>
            {{ $t('personal notes') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small icon @click="showNoteBox = true;++reRenderNotes" v-if="!showNoteBox" dark class="mr-2">
            <v-icon :color="systemDetails.textcolor" dark>mdi-plus</v-icon>
          </v-btn>
          <v-btn small icon dark v-if="selected.length > 0" @click="deleteNotes" class="mr-2">
            <v-icon :color="systemDetails.textcolor">mdi-delete</v-icon>
          </v-btn>
          <v-btn small icon dark @click="menu = false">
            <v-icon :color="systemDetails.textcolor">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list dense v-if="listOfNotes.length > 0" class="mt-2">
          <v-list-item v-for="(item, index) in listOfNotes" :key="index" link>
            <v-list-item-action>
              <v-checkbox v-model="item.isSelceted" @change="addToSelected($event, item)"
                color="primary"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-html="item.note" class="v-toolbar__title notes-overflow"></v-list-item-title>
              <v-list-item-subtitle>{{item.user_firstname}} {{item.user_lastname}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ $formatter.formatDate(item.created_on, 'DD.MM.YYYYTHH.mm.ss', `${userDetails.dateformat} HH:mm`) }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon x-small>
                <v-icon color="primary" @click="editNotes(index)">mdi-pencil-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <template v-else>
          <v-alert prominent text icon="mdi-information" :color="systemDetails.themecolor || 'info'" class="my-3 mx-5" >
            <v-row align="center">
              <v-col class="grow">{{ $t('noNotesAdded') }}</v-col>
            </v-row>
          </v-alert>
        </template>
        <div class="pa-2 ma-2 mb-0 justify-right">
          <template v-if="showNoteBox">
            <ckeditor :editor="editor" v-model="notesObj.notes" :config="noteConfig" ref="editor"></ckeditor>
            <v-flex class="text-right">
              <v-btn-toggle v-model="toggle_exclusive" class="justify-right">
                <v-btn small @click="saveNotes">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn small @click="showNoteBox = false, notesObj = {}">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-flex>
          </template>
        </div>
      </v-card>
    </v-menu>
    <v-row class="pa-0">
      <v-app-bar fixed app :color="systemDetails.themecolor">
        <v-app-bar-nav-icon>
          <v-btn icon dark small @click="$router.go(-1)">
            <v-icon :color="systemDetails.textcolor">mdi-arrow-left</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
        <v-app-bar-title :style="`color: ${systemDetails.textcolor}`" v-if="modelObj.number">#{{ modelObj.number }}</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-chip class="mx-1" color="cyan" small label text-color="white" v-if="mailsMerged">
          {{ singularPluralData(mailsMerged) }}
        </v-chip>
        <v-btn icon small dark :color="systemDetails.themecolor || 'primary'" @click="isShowUpdate = !isShowUpdate">
          <v-fade-transition>
            <v-icon v-if="isShowUpdate" :color="systemDetails.textcolor || 'white'">mdi-chevron-up</v-icon>
            <v-icon v-else :color="systemDetails.textcolor || 'white'">mdi-chevron-down</v-icon>
          </v-fade-transition>
        </v-btn>
      </v-app-bar>
      <v-expand-transition>
        <v-flex v-if="isShowUpdate" class="ticket-view lg12 md12 sm12 xs12 pa-0 mb-2">
          <v-tabs v-model="tab" :background-color="!$vuetify.theme.dark ? 'grey lighten-4' : ''" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" fixed-tabs :slider-color="systemDetails.themecolor">
            <v-tab v-for="(item, i) in tabs" :key="i">
              {{ item.name }} {{ item.id === 1 ? `(${listOfComments.length})` : '' }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item  v-for="(item, keyIndex) in tabs" :key="keyIndex">
              <template v-if="item.id === 0">
                <div class="mt-0">
                  <h4 v-if="modelObj.is_task" class="pl-3">{{ $t('createdBy') }} :</h4>
                  <form-data :references.sync="formReferences" :model="modelObj" ref="ticketViewEditReference">
                    <template slot="extraDetails">
                      <template v-for="(item, index) in modulesRelated">
                        <v-row class="ml-4 mr-4" :key="index">
                          <v-autocomplete  outlined dense hide-details :label="$t(item.label)" v-model="item.selectedValue" v-if="item.name === PROJECT" @keyup="searchProjects($event.target.value)"
                            :loading="projectLoader" :items="listOfProjects" item-text="data.name" item-value="_id" class="mb-4" clearable>
                          </v-autocomplete>
                          <v-autocomplete   outlined dense hide-details :label="$t(item.label)" v-model="item.selectedValue" v-else
                            :items="item.listOfValues" item-text="data.name" item-value="_id" class="mb-4" clearable>
                          </v-autocomplete>
                        </v-row>
                      </template>
                    </template>
                    <template slot="extraButtons" v-if="modelObj.is_task">
                    </template>
                  </form-data>
                </div>
              </template>
              <v-card flat v-else class="pa-0">
                <v-card-text class="px-1">
                  <comments-section :ticketNumber="modelObj.number" :assignedto="modelObj.assignedto" :listOfComments="listOfComments"></comments-section>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-expand-transition>
      <v-flex class="lg12 md12 sm12 xs12 pa-1">
        <v-card flat class="pb-1 custom-ckeditor-height">
          <v-card outlined class="mb-2 fullscreen" v-for="(item, index) in ticketMails" :key="index">
            <v-card-title class="pa-0 ma-0 pl-0 subtitle-1 font-weight-bold">
              <v-row class="pa-0 pl-0 ma-0">
                <template v-if="showFieldsForTask">
                  <v-col cols="10" lg="10" xs="10">
                    <v-text-field dense hide-details outlined v-model="updateMailInfo.subject"></v-text-field>
                  </v-col>
                  <v-col cols="2" xs="2">
                    <v-icon color="success" size="18" @click="updateMailValues">mdi-content-save</v-icon>
                    <v-icon color="error" size="18" @click="showFieldsForTask = false">mdi-close</v-icon>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="9" lg="9" xs="9" class="px-0 pl-2">{{item.subject}}</v-col>
                  <v-col cols="2" lg="2" xs="2" class="pa-0 pt-2 pl-2" v-if="!modelObj.is_task">
                    <v-menu>
                      <template #activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" icon v-if="!modelObj.is_task">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <template v-for="(button, idx) in ticketActions">
                          <v-list-item :key="idx" @click="button.click(item, index)" v-if="button.is_show">
                            <v-list-item-icon class="mr-4">
                              <v-icon :color="systemDetails.themecolor">{{ button.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>{{ button.label }}</v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="3" xs="3" class="text-end" v-else>
                    <v-icon size="19" color="primary" @click="showMailInfoEdit(item, index)" v-if="!showFieldsForTask">mdi-pencil</v-icon>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-row class="rounded-xl my-0" style="border: 1px solid rgba(0,0,0,0.2)">
              <v-col cols="12" lg="10" sm="12" class="py-0">
                <v-row class="pa-0" no-gutters>
                  <v-col cols="12" lg="6" md="6"  v-if="item.mailfrom && item.mailfrom.length > 0">
                    <v-card-text class="subtitle-2 pa-2">{{$t('from')}}:
                      <span class="mb-0 ml-5" small>{{item.mailfrom[0].Address}}</span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" lg="6" md="6"  v-if="item.mailto && item.mailto.length > 0">
                    <v-card-text class="subtitle-2 pa-2">{{$t('to')}}:
                      <span class="mb-0 ml-9" small>{{item.mailto.map(function(elem){return elem.Address}).join(",")}}</span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" lg="6" md="6"  v-if="item.mailcc && item.mailcc.length > 0">
                    <v-card-text class="subtitle-2 pa-2">{{$t('cc')}}:
                      <span class="mb-0 ml-9" small>{{item.mailcc.map(function(elem){return elem.Address}).join(",")}}</span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" lg="6" md="6"  v-if="item.mailbcc && item.mailbcc.length > 0">
                    <v-card-text class="subtitle-2 pa-2">{{$t('bcc')}}:
                      <span class="mb-0 ml-7" small> {{item.mailbcc.map(function(elem){return elem.Address}).join(",")}} </span>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-col cols="12" class="pt-0 mt-5">
              <v-sheet>
                <iframe :id="'content-frame' + index" :srcdoc="convertHtml(item.body)" frameborder="0" allowfullscreen width="100%" height="100%" v-if="!showFieldsForTask" :onload="setIframeHeight()"></iframe>
                <v-row class="pa-0 ma-0" v-else>
                  <v-col cols="12" lg="12">
                    <ckeditor :editor="editor" v-model="updateMailInfo.body" :config="editorConfig" ref="editor" v-if="modelObj.is_task && showFieldsForTask"></ckeditor>
                  </v-col>
                </v-row>
                <div v-if="item.attachments && item.attachments.length > 0">
                  <v-row class="justify-center">
                    <v-col cols="12" lg="1" md="1" sm="6" v-for="(attachments, index) in item.imageAttachments" :key="index">
                      <template>
                        <v-hover v-slot="{ hover }">
                          <v-img :lazy-src="`${attachmentsURL}${attachments.filepath}`" :src="`${attachmentsURL}${attachments.filepath}`" :aspect-ratio="16/9" contain>
                            <v-expand-transition>
                              <div v-if="hover"  class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text" style="height: 100%;">
                                <v-icon class="pl-7" @click="downloadFile(attachments._id)" dark>mdi-download</v-icon>
                              </div>
                            </v-expand-transition>
                          </v-img>
                        </v-hover>
                      </template>
                    </v-col>
                    <v-col cols="12" lg="4" md="4" sm="6" v-for="(other, oIindex) in item.otherAttachments" :key="`other_${oIindex}`">
                      <template>
                        <v-card outlined height="50">
                          <div class="attchment">
                            <v-row class="mt-0 pr-3">
                              <v-col sm="2" lg="1" cols="2" md="2">
                                <v-icon class="mr-0 ml-2" :color="other.color ? other.color : ''">{{ other.icon }}</v-icon>
                              </v-col>
                              <v-col sm="8" lg="9" cols="8" md="8"  class="text-truncate">
                                <span class="mt-1 pt-4 pl-0 caption">{{ other.originalfilename }}</span><br><br>
                              </v-col>
                              <v-col sm="1" lg="1" cols="1" md="1">
                                <v-icon @click="downloadFile(other._id)">mdi-download</v-icon>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                </div>
                <v-footer>
                  <v-spacer></v-spacer>
                  <div>{{ $formatter.formatDate(item.datetime, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`)}}</div>
                </v-footer>
              </v-sheet>
            </v-col>
            <v-dialog v-model="showReplyForwardMenu" fullscreen style="overflow-x: hidden" content-class="fullscreen" transition="scroll-y-reverse-transition">
              <v-card flat height="100vh">
                <v-form v-if="selectedMailItem" :ref="`mailForm_${index}`">
                  <v-card-title class="py-2" :style="`background-color: ${systemDetails.themecolor}; color: ${systemDetails.textcolor}; height: 50px`">
                    {{ actionType === 1 ? $t('reply') : actionType === 2 ? $t('replyAll') : actionType === 3 ? $t('forward') : '' }}
                    <v-spacer></v-spacer>
                    <v-btn :style="`color: ${systemDetails.textcolor}`" icon dark @click="showReplyForwardMenu = false; selectedMailItem = null; actionType = 0;">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-4 px-0 overflow-x-hidden overflow-y-auto" style="height: calc(100vh - 100px)">
                    <v-row v-if="!modelObj.is_task" class="mx-0">
                      <v-col cols="12" class="pa-0 pt-1">
                        <v-btn color="primary" x-small dark class="mx-2" @click="showHideInput(selectedMailItem, index, 'cc')">
                          {{$t('cc')}}
                        </v-btn>
                        <v-btn color="info" x-small dark class="mx-2" @click="showHideInput(selectedMailItem, index, 'bcc')">
                          {{$t('bcc')}}
                        </v-btn>
                      </v-col>
                      <v-col cols="12" lg="10" md="9" sm="8" class="pt-2 pb-0 mb-2">
                        <div class="combo">
                          <v-combobox class="py-1" v-model="selectedMailItem.mailTo" hide-details  hide-selected  :rules="$_multiSelectValidation" :label="$t('to')" multiple persistent-hint small-chips outlined dense></v-combobox>
                        </div>
                        <div class="combo">
                          <v-combobox class="py-1" v-model="selectedMailItem.mailCc" hide-details hide-selected  :label="$t('cc')" multiple persistent-hint small-chips  outlined dense v-if="selectedMailItem.showMailCc"></v-combobox>
                        </div>
                        <div class="combo">
                          <v-combobox class="py-1" v-model="selectedMailItem.mailBcc" hide-details hide-selected  :label="$t('bcc')" multiple persistent-hint small-chips outlined dense v-if="selectedMailItem.showMailBcc"></v-combobox>
                        </div>
                        <div>
                          <v-text-field label="Attachments"  prepend-icon='mdi-paperclip' dense outlined hide-details @click='pickFile(index)'></v-text-field>
                          <input type="file" style="display: none" :id="`attachments_${index}`" :ref="`attachments_${index}`"  multiple @change="onFilePicked($event, index)">
                        </div>
                      </v-col>
                      <v-row class="px-3">
                        <v-col lg="2" cols="4" md="3" sm="4" v-for="(image, imageIndex) in item.listOfFiles" :key="`image_${imageIndex}`">
                          <v-hover v-slot="{ hover }">
                            <v-img :src="image" height="80" :aspect-ratio="16/9" width="200" contain>
                              <v-expand-transition>
                                <div v-if="hover"  class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text justify-center" style="height: 100%;">
                                  <v-icon @click="removeAttachment(imageIndex, 'listOfFiles', index)" dark>mdi-close-circle-outline</v-icon>
                                </div>
                              </v-expand-transition>
                            </v-img>
                          </v-hover>
                        </v-col>
                        <v-col v-for="(files, fileIndex) in item.listOfOtherFiles" :key="`file_${fileIndex}`" cols="12" lg="3" md="4" sm="6">
                          <v-card outlined height="50">
                            <v-row class="mt-0">
                              <v-col lg="1" cols="2" md="1" sm="2">
                                <v-icon class="mr-4 ml-2" :color="files.color">{{ files.icon }}</v-icon>
                              </v-col>
                              <v-col lg="8" cols="7" sm="7" md="8" class="text-truncate">
                                <span class="mt-1 pt-4 pl-3 caption">{{ files.name }}</span><br><br>
                              </v-col>
                              <v-col lg="2" cols="3" sm="3" md="2">
                                <v-icon color="error" @click="removeAttachment(fileIndex, 'listOfOtherFiles', index)">mdi-close-circle-outline</v-icon>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-row class="mx-0">
                      <v-col class="pb-0">
                        <span v-if="modelObj.is_task">{{$t('description')}}</span>
                        <span v-else>{{$t('body')}}</span>
                        <div class="pwa-appnote-editor">
                          <ckeditor :editor="editor" v-model="selectedMailItem.mailBody" :config="editorConfig" ref="editor"></ckeditor>
                        </div>
                      </v-col>
                    </v-row>
                    <v-card-actions class="justify-left mt-5">
                      <v-btn color="info" @click="sendMailOrAttachment(item, index)" :loading="sendMailLoading" small>{{$t('send')}}</v-btn>
                      <v-btn color="error" @click="cancelMail(item, index)" small class="mx-2">{{$t('cancel')}}</v-btn>
                   </v-card-actions>
                  </v-card-text>
                   <!-- <v-card-actions class="justify-left mt-5">
                      <v-btn color="info" @click="sendMailOrAttachment(item, index)" :loading="sendMailLoading" small>{{$t('send')}}</v-btn>
                      <v-btn color="error" @click="cancelMail(item, index)" small class="mx-2">{{$t('cancel')}}</v-btn>
                  </v-card-actions> -->
                </v-form>
              </v-card>
            </v-dialog>
          </v-card>
          <template v-if="!fieldsLoading && ticketMails.length === 0">
            <v-alert prominent text icon="mdi-information" :color="systemDetails.themecolor || 'info'" class="mt-2" >
              <v-row align="center">
                <v-col class="grow">{{ $t('noRecords') }}</v-col>
              </v-row>
            </v-alert>
          </template>
        </v-card>
      </v-flex>
    </v-row>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showDeleteDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title class="py-2 px-4 error white--text">
          {{ $t('confirmation') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3 pt-0 pb-1">
          <v-checkbox v-model="isDeleteMails" :label="$t('deleteMailsTo')"></v-checkbox>
          <span v-if="isDeleteMails" color="error">
          {{$t('note')}} : <code>{{$t('deleteNote')}}</code>
          </span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="error" id="priority" @click="deleteConfirmed">{{ $t('delete') }}</v-btn>
          <v-btn small color="info" id="priorityclose" @click="showDeleteDialog=false,isDeleteMails=false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Status close confirm -->
    <v-dialog v-model="cancloseDialog" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('confirmation') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <h4> {{ $t('noUserAssigned') }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="setAssingedTo()" :loading="cancloseLoading">{{ $t('yes') }}</v-btn>
          <v-btn color="error" @click="closeConfirmDialog">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import CommonFunctions from './mixin'
import Editor from '@/ckeditor'
export default {
  mixins: [CommonFunctions],
  data () {
    return {
      modelObj: {
        _id: this.$route.params.ticket_id
      },
      ticketTerm: null,
      bodySignature: null,
      enableEditMode: false,
      mailsMerged: 0,
      MODULE_URL: 'tickets',
      ticketMails: [],
      editor: Editor,
      editorConfig: {
        removePlugins: ['ImageUpload', 'ElementsPath'],
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: ['bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
        }
      },
      noteConfig: {
        height: '250px',
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: ['bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'undo', 'redo']
        }
      },
      notesObj: {
        _id: '',
        notes: ''
      },
      panel: [0],
      tab: null,
      sendMailLoading: false,
      isDeleteMails: false,
      showDeleteDialog: false,
      toggle_exclusive: 2,
      emailRegex: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
      listOfNotes: [],
      fav: true,
      menu: false,
      message: false,
      hints: true,
      showNoteBox: false,
      selected: [],
      NOTES_MODULE: 'ticketnotes',
      backupTicket: {},
      isLoading: false,
      listOfComments: [],
      listOfAccounts: [],
      accountLoading: false,
      /* pwa */
      isShowUpdate: true,
      fieldsLoading: false,
      showReplyForwardDialog: false,
      actionType: 0, // reply-1, replyall-2, forward-3
      selectedMailItem: null, // for reply,replyall & forward
      showReplyForwardMenu: false,
      showReplyForwardMenuWidth: 300,
      enableSearch: true,
      showFieldsForTask: false,
      updateMailInfo: {},
      modulesRelated: [],
      cancloseLoading: false,
      cancloseDialog: false,
      userSignature: '',
      attachmentsURL: process.env.VUE_APP_ATTACHMENTS,
      listOfFiles: [],
      listOfOtherFiles: [],
      attachmentsList: [],
      imagesTypes: ['image/bmp', 'image/png', 'image/jpg', 'image/jpeg'],
      attachmentsFiles: [],
      projectLoader: false,
      listOfProjects: [],
      reRenderNotes: 0,
      showConvertSale: false
    }
  },
  components: {
    'form-data': () => import('@/components/FormTemplate.vue'),
    'comments-section': () => import('./Comments.vue')
  },
  computed: {
    ...mapGetters(['formType', 'getUsers', 'getListOfTicketPriorities', 'getListOfTicketStatus', 'getListOfTicketCategory', 'getsignalRConnection', 'getListOfGroups', 'userDetails', 'listOfModules', 'systemDetails']),
    formReferences () {
      return {
        title: '',
        properties: [{
          model: 'account_id',
          live_search_model: 'accountSearch',
          type: this.formType.AUTO_COMPLETE_LIVE,
          items: this.listOfAccounts,
          select_text: 'data.name',
          select_value: '_id',
          class: 'sm12',
          label: this.$t('account'),
          loading: this.accountLoading,
          change: this.loadValues
        }, {
          model: 'assignedto',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getUsersAndGroups,
          select_text: 'name',
          select_value: 'alternateid',
          change: this.changeStatus,
          label: this.$t('assignedTo'),
          class: 'sm12'
        }, {
          model: 'priority',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getListOfTicketPriorities,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('changePriority'),
          class: 'sm12'
        }, {
          model: 'status',
          type: this.formType.AUTO_COMPLETE,
          rules: [],
          items: this.getListOfTicketStatus,
          select_text: 'name',
          select_value: '_id',
          label: this.$t('status'),
          class: 'sm12'
        }, {
          model: 'category_id',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketCategory,
          select_text: 'name',
          select_value: '_id',
          class: 'sm12',
          label: this.$t('category')
        }
        // , {
        //   model: 'tags',
        //   type: this.formType.COMBOBOX,
        //   rules: [],
        //   multiple: true,
        //   items: [],
        //   label: this.$t('tags'),
        //   class: 'sm12'
        // }
        ],
        buttons: [{
          name: 'update_ticket',
          color: 'success',
          label: this.$t('update'),
          click: () => this.checkStatusClosed(),
          // eslint-disable-next-line
          update_loading: false,
          is_show: true
        }, {
          name: 'delete_ticket',
          color: 'error',
          label: this.$t('delete'),
          click: () => this.deleteHandler(),
          loading: false,
          is_show: true
        }, {
          name: 'convert_ticket',
          color: 'info',
          label: this.modelObj.is_task ? this.$t('convertToTicket') : this.$t('convertToTask'),
          click: () => this.convertTypeHandler(),
          is_show: true,
          loading: false
        }, {
          name: 'convert_sale',
          color: 'info',
          class: 'mt-3',
          label: this.$t('convertToSale'),
          click: () => this.convertToSale(),
          is_show: this.showConvertSale,
          loading: false
        }]
      }
    },
    ticketActions () {
      return [
        { icon: 'mdi-reply', label: this.$t('reply'), click: (item, index) => this.replyMail(item, index), is_show: true },
        { icon: 'mdi-reply-all', label: this.$t('replyAll'), click: (item, index) => this.replyAllMail(item, index), is_show: true },
        { icon: 'mdi-forward', label: this.$t('forward'), click: (item, index) => this.forwardMail(item, index), is_show: !this.modelObj.is_task }
      ]
    },
    getUsersAndGroups () {
      const userList = this.$formatter.cloneVariable(this.getUsers)
      const groupList = this.$formatter.cloneVariable(this.getListOfGroups)
      const list = [...userList, ...groupList]
      return list
    },
    tabs () {
      return [
        { id: 0, name: this.$t('details') },
        { id: 1, name: this.$t('comments') }
      ]
    }
  },
  watch: {
    'modelObj.accountSearch' (val) {
      if (val === undefined) return
      if (val && !val.includes('-') && this.enableSearch) this.loadAccountList(val)
    },
    // '$vuetify.breakpoint': {
    //   deep: true,
    //   handler () {
    //     this.showReplyForwardForm()
    //   }
    // },
    'showReplyForwardMenu' (val) {
      if (val) document.getElementsByTagName('BODY')[0].parentElement.classList.add('noscroll')
      else document.getElementsByTagName('BODY')[0].parentElement.classList.remove('noscroll')
    }
  },
  mounted () {
    const domain = window.localStorage.getItem('businessflow365PWAdomain')
    this.attachmentsURL = domain ? `${this.attachmentsURL}${domain}/` : this.attachmentsURL
    this.fieldsLoading = true
    this.getTicketTerm()
    this.getListOfNotes()
    this.$store.dispatch('getUsers')
    this.$store.dispatch('getListOfGroups')
    this.$store.dispatch('getTicketPriority')
    this.$store.dispatch('getTicketStatus')
    this.$store.dispatch('getTicketCategory')
    const text = this.$t('deleteMsg')
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.NOTES_MODULE) {
        const payload = { ids: data.ids, url: `${this.NOTES_MODULE}/delete_multiple`, module: this.NOTES_MODULE }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$root.$on('loadComments', (data) => {
      this.getAllComments(data)
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.NOTES_MODULE) {
        this.selected = []
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getListOfNotes()
      }
    })
  },
  methods: {
    setIframeHeight () {
      setTimeout(() => {
        for (let index = 0; index < this.ticketMails.length; index++) {
          const iframe = document.getElementById('content-frame' + index)
          iframe.onload = function () {
            iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 50) + 'px'
          }
        }
      }, 1)
    },
    singularPluralData (value) {
      let msg = `${this.$t('merged')} ${value}`
      msg += value > 1 ? this.$t('mails') : this.$t('mail')
      return msg
    },
    changeStatus () {
      // if (this.modelObj.status === parseInt(process.env.VUE_APP_TICKET_STAUS_NEW)) this.modelObj.status = parseInt(process.env.VUE_APP_TICKET_STAUS_INPROGRESS)
    },
    addToSelected (event, item) {
      if (event) {
        this.selected.push(item)
      } else {
        const result = this.selected.filter(x => x._id !== item._id)
        if (result) {
          this.selected = result
        }
      }
    },
    deleteNotes () {
      const ids = this.selected.map(x => x._id)
      const data = { module: this.NOTES_MODULE, ids }
      this.$eventBus.$emit('deleteItems', data)
    },
    backToList () {
      this.modulesRelated = []
      const hasHistory = window.localStorage.getItem('pathHistory')
      if (hasHistory && hasHistory !== 'null') {
        window.localStorage.setItem('pathHistory', null)
        this.$router.push(hasHistory)
      } else this.$router.push(`/${this.MODULE_URL}`)
    },
    toggleEditMode () {
      this.enableEditMode = !this.enableEditMode
    },
    supportingList (type, text) {
      text = this.$refs.ticketViewEditReference.$refs[text][0].lazySearch
      const model = { module: type, dialog: true, text }
      this.$store.commit('supportingList', model)
    },
    getListOfNotes () {
      const query = [{ $match: { $and: [{ Ticket_Id: `${this.modelObj._id}` }, { User_Id: `${this.userDetails._id}` }] } }]
      this.$api.execute('post', '/ticketnotes/query', query).then(response => {
        this.listOfNotes = response.data
        this.listOfNotes.forEach(element => {
          element.showEdit = false
        })
      })
    },
    loadAccountList (searchTerm) {
      this.accountLoading = true
      this.listOfAccounts = []
      const model = {
        searchterm: searchTerm || ''
      }
      this.$api.execute('get', `moduledata/${this.ACCOUNT}/search?searchterm=${model.searchterm}`).then((response) => {
        this.listOfAccounts = response.data
      }).finally(() => {
        this.accountLoading = false
      })
    },
    saveNotes () {
      const model = {
        _id: this.$formatter.cloneVariable(this.notesObj._id) ? this.$formatter.cloneVariable(this.notesObj._id) : undefined,
        ticket_id: this.modelObj._id,
        user_id: this.userDetails._id,
        note: this.$formatter.cloneVariable(this.notesObj.notes)
      }
      this.$api.execute(model._id ? 'put' : 'post', model._id ? `/ticketnotes/${model._id}` : '/ticketnotes', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
        // if (this.modelObj.assignedto) {
        //   let assignedTo = this.$formatter.cloneVariable(this.modelObj.assignedto)
        //   assignedTo = assignedTo.split('_')
        //   if (assignedTo[0] === 'group') {
        //     let item = this.getListOfGroups.find(x => x._id == assignedTo[1])
        //     let usersarray = []
        //     // let query = {
        //     //   filter: `groupid eq ${assignedTo[1]} and isactive eq 1`
        //     // }
        //     let  msg = `${this.$store.state.auth.userDetails.name} ${ model._id ? this.$t('updated') : this.$t('added') } ${this.$t('noteIn')} ${this.modelObj.number}`
        //     this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
        //       usersarray = result.data.map(x => x.user_id)
        //     }).finally(() => {
        //       this.getsignalRConnection.invoke('SendNotification', usersarray, msg, response.data._id)
        //     })
        //   } else {
        //     if (this.$store.state.auth.userDetails._id != assignedTo[1]) {
        //       let  msg = `${this.$store.state.auth.userDetails.name} ${ model._id ? this.$t('updated') : this.$t('added') } ${this.$t('noteIn')} ${this.modelObj.number}`
        //       this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg, model.ticket_id)
        //     }
        //   }
        // }
        this.showNoteBox = false
        this.notesObj = {}
        this.getListOfNotes()
      })
    },
    editNotes (index) {
      const note = this.$formatter.cloneVariable(this.listOfNotes[index])
      this.notesObj._id = note._id
      this.notesObj.notes = note.note
      this.showNoteBox = true
    },
    getSingleRecordHandler () {
      const query = [
        { $addFields: { convertedId: { $toString: '$_id' } } },
        { $match: { $and: [{ convertedId: `${this.modelObj._id}` }] } },
        {
          $lookup: {
            let: { accountId: '$Account_Id' },
            from: 'Account',
            pipeline: [{ $match: { $expr: { $eq: ['$_id', { $convert: { input: '$$accountId', to: 'objectId', onError: { error: true }, onNull: { isnull: true } } }] } } }, { $project: { _id: 1, Data: 1 } }],
            as: 'account_info'
          }
        },
        { $lookup: { from: 'Mail', localField: 'MailId', foreignField: 'MailId', as: 'mail_info' } },
        { $lookup: { let: { mailboxid: '$MailboxId' }, from: 'MailConfiguration', pipeline: [{ $match: { $expr: { $eq: ['$_id', { $convert: { input: '$$mailboxid', to: 'objectId' } }] } } }], as: 'mailbox' } }
      ]
      this.$api.execute('post', `${this.MODULE_URL}/query`, query).then(response => {
        const result = response.data[0]
        if (result && result.tags) result.tags = JSON.parse(result.tags)
        if (result.assignedto) {
          result.assignedto = `user_${result.assignedto}`
        } else if (result.assignedto_group) {
          result.assignedto = `group_${result.assignedto_group}`
        }
        this.backupTicket = this.$formatter.cloneVariable(result)
        this.modelObj = result
        if (result.account_info.length > 0) {
          this.enableSearch = false
          this.listOfAccounts.push(result.account_info[0])
          this.modelObj.account_id = result.account_id
        }
        setTimeout(() => {
          const modulesRelated = this.listOfModules.filter(x => x.include_ticket && x.name !== this.ACCOUNT && x.isactive)
          this.modulesRelated = modulesRelated
          if (this.modulesRelated.length > 0) {
            const hasSale = this.modulesRelated.find(x => x.name === this.SALE)
            let hasSaleManagePermission = false
            const hasSalePermission = this.listOfModules.find(x => x.name === this.SALE)
            if (hasSalePermission) hasSaleManagePermission = hasSalePermission.hasManagePermission
            this.showConvertSale = !!hasSale && !!hasSaleManagePermission
          }
          this.enableSearch = true
          this.loadValues()
          if (this.modelObj.modulerelations) {
            this.modulesRelated.forEach(element => {
              const result = this.modelObj.modulerelations.find(x => x.module === element.name)
              if (result) {
                element.selectedValue = result._id
              }
            })
          }
        }, 200)
        this.getTicketMails()
        this.getAllComments(this.modelObj._id)
        this.bodySignature = `<p></p><p></p><p></p><p><strong>[${this.ticketTerm ? `${this.ticketTerm}` : ''}#${this.modelObj.number}]</strong><iframe id="userSign" frameborder="0" width="100%"></iframe></p>`
        // this.bodySignature = `<p></p><p></p><strong>[${this.ticketTerm ? `${this.ticketTerm} ` : ''}#${this.modelObj.number}]</strong>`
      }).finally(() => {
        this.fieldsLoading = false
      })
    },
    closeConfirmDialog () {
      this.cancloseDialog = false
      this.cancloseLoading = false
    },
    getAllComments (id) {
      const query = [{ $match: { $and: [{ Ticket_Id: `${id}` }] } },
        {
          $lookup: {
            let: { userId: '$Updated_By' },
            from: 'User',
            pipeline: [
              {
                $match: {
                  $expr: {
                    $eq: ['$_id', {
                      $convert: {
                        input: '$$userId',
                        to: 'objectId',
                        onError: { error: true },
                        onNull: { isnull: true }
                      }
                    }]
                  }
                }
              },
              { $project: { _id: 1, FirstName: 1, LastName: 1 } }
            ],
            as: 'user_info'
          }
        }
      ]
      this.$api.execute('post', '/ticketcomments/query', query).then(response => {
        this.listOfComments = response.data
        this.listOfComments.forEach(element => {
          // element.modified_at = this.$formatter.fromUtcToLocal(element.modified_at, 'DD.MM.YYYYTHH:mm:ss')
          if (element.updated_by === -100) {
            element.user_firstname = 'Super'
            element.user_lastname = 'Admin'
          } else if (element.user_info.length > 0) {
            element.user_firstname = element.user_info[0].firstname
            element.user_lastname = element.user_info[0].lastname
          }
          if (!element.user_firstname) element.user_firstname = 'Unknown'
          element.showEdit = false
        })
      })
    },
    getTicketTerm () {
      this.$api.execute('get', 'systemconfigurations')
        .then(({ data }) => {
          if (data) this.ticketTerm = (data.systemconfiguration && data.systemconfiguration.ticketterm) ? data.systemconfiguration.ticketterm : ''
          this.getSingleRecordHandler()
          this.enableEditMode = true
        })
    },
    getTicketMails () {
      const obj = { id: this.modelObj.mailid }
      this.$api.execute('post', 'mails/get_all', obj).then(response => {
        if (response) {
          let mergedMailsCount = 0
          const mails = this.$formatter.cloneVariable(response.data)
          const tempAllMails = []
          mails.forEach(mail => {
            if (mail.mergedmails && mail.mergedmails.length) {
              mergedMailsCount += mail.mergedmails.length
              tempAllMails.push(...mail.mergedmails)
              mail.mergedmails = null
            }
            tempAllMails.push(mail)
          })
          const uniqueItemFound = []
          tempAllMails.sort((a, b) => {
            if (a.mailid === b.mailid) uniqueItemFound.push(tempAllMails.indexOf(a))
          })
          /* Remove duplicate objects from array previous push is having only 1 index value remove, this is code is updated with multiple */
          uniqueItemFound.forEach(index => {
            tempAllMails.splice(index, 1)
          })
          var clonedArray = tempAllMails.map(function (mail) {
            mail.mailfrom = mail.mailfrom ? JSON.parse(mail.mailfrom) : ''
            mail.mailto = mail.mailto ? JSON.parse(mail.mailto) : ''
            mail.showMailCc = false
            mail.showMailBcc = false
            mail.mailcc = mail.mailcc ? JSON.parse(mail.mailcc) : ''
            mail.mailbcc = mail.mailbcc ? JSON.parse(mail.mailbcc) : ''
            mail.isShowMailForm = false
            return mail
          })
          clonedArray.sort((a, b) => {
            const date1 = a.datetime
            const date2 = b.datetime
            return this.$formatter.sortWithDates(date2, date1, 'DD.MM.YYYYTHH:mm:ss')
          })
          clonedArray.forEach(element => {
            element.imageAttachments = []
            element.otherAttachments = []
            if (element.attachments && element.attachments.length > 0) {
              element.attachments.forEach(attachment => {
                const getExtensionType = this.mimeTypeOfDocument(attachment.extension)
                if (getExtensionType === 'mdi-file-image') {
                  element.imageAttachments.push({ ...attachment, icon: getExtensionType, color: '' })
                } else element.otherAttachments.push({ ...attachment, icon: getExtensionType.icon, color: getExtensionType.color })
              })
            }
          })
          this.mailsMerged = mergedMailsCount
          this.ticketMails = this.$formatter.cloneVariable(clonedArray)
        }
      }).finally(() => {
        var imgs = document.getElementsByTagName('img')
        for (var i = 0; i < imgs.length; i++) {
          imgs[i].style.removeProperty('zoom')
        }
        // if (document.getElementsByTagName("img").style) {
        //   document.getElementsByTagName("img").removeAttribute("style");
        //   document.getElementsByTagName("img").removeAttribute("style");
        // }
      })
    },
    checkStatusClosed () {
      const model = this.$formatter.cloneVariable(this.modelObj)
      if (!model.assignedto && !model.assignedto_group) {
        const statusItem = this.getListOfTicketStatus.find(x => x._id === model.status)
        if (statusItem && statusItem.canclose) {
          this.cancloseDialog = true
          // this.statusItem = item
        } else this.saveUpdateHandler()
      } else {
        this.saveUpdateHandler()
      }
    },
    setAssingedTo () {
      this.cancloseLoading = true
      this.modelObj.assignedto = `user_${this.userDetails._id}`
      this.cancloseDialog = false
      this.cancloseLoading = false
      this.saveUpdateHandler()
    },
    async saveUpdateHandler (type) {
      if (this.$refs.ticketViewEditReference[0].$refs.validateForm.validate()) {
        this.update_loading = true
        const model = this.$formatter.cloneVariable(this.modelObj)
        if (this.listOfFiles.length > 0 || this.listOfOtherFiles.length > 0) model.attachments = await this.saveAttachmentsHandler()
        const modelRelatedModule = this.$formatter.cloneVariable(this.modulesRelated)
        model.modulerelations = []
        // If any module has ticket relation
        if (modelRelatedModule.length > 0) {
          model.modulerelations = modelRelatedModule.map(x => { return { id: x.selectedValue, module: x.name } })
        }
        model.tags = model.tags ? JSON.stringify(model.tags) : ''
        let assignedTo = []
        const assignedToBackup = model.assignedto
        if (model.assignedto) {
          assignedTo = model.assignedto.split('_')
          if (assignedTo[0] === 'user') {
            model.assignedto = assignedTo[1]
            model.assignedto_group = null
          } else {
            model.assignedto = null
            model.assignedto_group = assignedTo[1]
          }
        }
        const url = this.MODULE_URL
        this.$api.saveUpdateHandler(url, model).then((response) => {
          this.$root.$emit('snackbar', { ...response.snackbar, text: response.snackbar.text })
          let msg = ''
          if (assignedTo.length > 0) {
            if (model.status !== this.backupTicket.status) {
              const oldStatus = this.getListOfTicketStatus.find(x => x._id === this.backupTicket.status)
              const newStatus = this.getListOfTicketStatus.find(x => x._id === model.status)
              if (!oldStatus) msg = `${this.$t('statusSet')} ${newStatus.name} ${this.$t('for')} ${this.backupTicket.number} ${this.$t('by')} ${this.userDetails.name}`
              else msg = `${this.$t('statusChanged')} ${oldStatus.name} ${this.$t('to')} ${newStatus.name} ${this.$t('for')} ${this.backupTicket.number} ${this.$t('by')} ${this.userDetails.name}`
            } else if (assignedToBackup !== this.backupTicket.assignedto) {
              if (assignedTo[0] === 'user') msg = `${this.$t('assignedtoMessage')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
              else msg = this.sendAssignedNotification(this.backupTicket.number, assignedTo[1])
            } else if (model.priority !== this.backupTicket.priority) {
              const oldPriority = this.getListOfTicketPriorities.find(x => x._id === this.backupTicket.priority)
              const newPriority = this.getListOfTicketPriorities.find(x => x._id === model.priority)
              if (!oldPriority) msg = `${this.$t('prioritySet')} ${newPriority.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
              else msg = `${this.$t('priorityChanged')} ${oldPriority.name} ${this.$t('to')} ${newPriority.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
            } else if (model.category_id !== this.backupTicket.category_id) {
              const oldCategory = this.getListOfTicketCategory.find(x => x._id === this.backupTicket.category_id)
              const newCategory = this.getListOfTicketCategory.find(x => x._id === model.category_id)
              if (!oldCategory) msg = `${this.$t('categorySet')} ${newCategory.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
              else msg = `${this.$t('categoryChanged')} ${oldCategory.name} ${this.$t('to')} ${newCategory.name} ${this.$t('for')} ${this.backupTicket.number}  ${this.$t('by')} ${this.userDetails.name}`
            }
            if (assignedTo[0] === 'group') {
              // const usersarray = []
              // let query = { filter: `groupid eq ${assignedTo[1]} and isactive eq 1` }
              // this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
              //   usersarray = result.data.map(x => x.user_id)
              // }).finally(() => {
              //   this.getsignalRConnection.invoke('SendNotification', usersarray, msg, response._id)
              // })
            } else {
              if (assignedTo[1] !== this.userDetails._id) this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg, response._id)
            }
          }
        }).finally(() => {
          this.update_loading = false
          this.getSingleRecordHandler()
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    async saveAttachmentsHandler () {
      var formData = new FormData()
      var filesList = this.attachmentsFiles
      if (filesList !== null && filesList.length > 0) {
        filesList.forEach(file => {
          formData.append(file.name, file)
        })
      }
      return new Promise((resolve, reject) => {
        this.$api.execute('post', 'mailattachments/upload', formData)
          .then(({ data }) => {
            if (data && data.length) {
              const attachmentIds = data.map(x => x._id)
              resolve(attachmentIds)
            }
          })
      })
    },
    async onFilePicked (e, index) {
      const item = this.$formatter.cloneVariable(this.ticketMails[index])
      const files = e.target.files
      for (let i = 0; i < files.length; i++) {
        if (files[i]) {
          if (!item.attachmentsList) item.attachmentsList = []
          item.attachmentsList.push(files[i].name)
          if (!item.attachmentsFiles) item.attachmentsFiles = []
          item.attachmentsFiles.push(files[i])
          if (this.imagesTypes.includes(files[i].type)) {
            const base64 = await this.toBase64(files[i])
            if (!item.listOfFiles) item.listOfFiles = []
            item.listOfFiles.push(base64)
            this.$set(this.ticketMails, index, item)
          } else {
            if (!item.listOfOtherFiles) item.listOfOtherFiles = []
            const iconObj = this.getMimeTypeIcons(files[i].type)
            item.listOfOtherFiles.push({ name: files[i].name, type: files[i].type, ...iconObj })
            this.$set(this.ticketMails, index, item)
          }
        }
      }
    },
    removeAttachment (fileIndex, listname, mainIndex) {
      const item = this.$formatter.cloneVariable(this.ticketMails[mainIndex])
      item[listname].splice(fileIndex, 1)
      this.$set(this.ticketMails, mainIndex, item)
    },
    pickFile (index) {
      const name = `attachments_${index}`
      document.getElementById(name).click()
    },
    sendAssignedNotification (number, groupID, ticketid) {
      const group = this.getListOfGroups.find(x => x._id === groupID)
      return `${group.name} is assigned for ${number} by ${this.userDetails.name}`
    },
    deleteConfirmed () {
      const modelObj = { data: { ids: [this.modelObj._id], delete_mails: this.isDeleteMails } }
      this.$api.execute('delete', `${this.MODULE_URL}/delete_multiple`, modelObj)
        .then(response => {
          if (response && response.data) {
            const text = 'deleteSuccess'
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
            this.$router.push(`/${this.MODULE_URL}`)
          }
        })
        .finally(() => {
          this.showDeleteDialog = false
        })
    },
    deleteHandler () {
      this.showDeleteDialog = true
    },
    convertHtml (htmlString) {
      return htmlString
    },
    replyMail (item, index) {
      const mailAddress = this.ticketMails[index].mailfrom && this.ticketMails[index].mailfrom.length > 0 ? item.mailfrom.map(function (elem) { return elem.Address }) : []
      const itemObj = Object.assign({ ...item }, {
        isShowMailForm: true,
        mailTo: mailAddress,
        mailBody: !this.modelObj.is_task ? this.bodySignature : '',
        showMailCc: false,
        showMailBcc: false,
        mailCc: [],
        mailBcc: []
      })
      this.$set(this.ticketMails, index, itemObj)
      this.actionType = 1
      this.showReplyForwardForm(this.ticketMails[index])
    },
    replyAllMail (item, index) {
      // const mailAddress = this.ticketMails[index].mailfrom && this.ticketMails[index].mailfrom.length > 0 ? item.mailfrom.map(function (elem) { return elem.Address }) : []
      let mailAddress = this.ticketMails[index].mailto && this.ticketMails[index].mailto.length > 0 ? item.mailto.map(function (elem) { return elem.Address }) : []
      const from = this.ticketMails[index].mailfrom && this.ticketMails[index].mailfrom.length > 0 ? item.mailfrom.map(function (elem) { return elem.Address }) : []
      if (from) {
        mailAddress = [...mailAddress, ...from]
      }
      const mailCc = item.mailcc.length > 0 ? item.mailcc.map(function (elem) { return elem.Address }) : []
      const mailBcc = item.mailbcc.length > 0 ? item.mailbcc.map(function (elem) { return elem.Address }) : []
      if (this.modelObj.mailbox.length > 0) mailAddress = mailAddress.filter(x => x !== this.modelObj.mailbox[0].username)
      const itemObj = Object.assign({ ...item }, {
        isShowMailForm: true,
        mailTo: mailAddress,
        mailCc,
        mailBcc,
        mailBody: !this.modelObj.is_task ? this.bodySignature : '',
        showMailCc: mailCc.length > 0,
        showMailBcc: mailBcc.length > 0
      })
      this.$set(this.ticketMails, index, itemObj)
      this.actionType = 2
      this.showReplyForwardForm(this.ticketMails[index])
    },
    forwardMail (item, index) {
      // const mailAddress = this.ticketMails[index].mailfrom && this.ticketMails[index].mailfrom.length > 0 ? item.mailfrom.map(function (elem) { return elem.Address }) : []
      const itemObj = Object.assign({ ...item }, { isShowMailForm: true, mailTo: [], mailBody: !this.modelObj.is_task ? this.bodySignature : '' })
      this.$set(this.ticketMails, index, itemObj)
      this.actionType = 3
      this.showReplyForwardForm(this.ticketMails[index])
    },
    showReplyForwardForm (item) {
      if (item) {
        this.selectedMailItem = this.$formatter.cloneVariable(item)
        this.showReplyForwardMenu = true
      }
      this.showReplyForwardMenuWidth = +(document.getElementById('container-div').offsetWidth)
      setTimeout(() => {
        let signature = ''
        const currentUser = this.getUsers.find(x => x._id === this.userDetails._id)
        if (currentUser && currentUser.signature) {
          signature = `${currentUser.signature}`
          document.getElementById('userSign').srcdoc = signature
          setTimeout(() => {
            const element = document.getElementById('userSign')
            element.style.height = (element.contentWindow.document.all[2].offsetHeight + 30) + 'px'
            element.style.pointerEvents = 'none'
          }, 300)
        }
      }, 500)
    },
    showHideInput (item, index, field) {
      if (field === 'cc') {
        item.showMailCc = !item.showMailCc
      } else item.showMailBcc = !item.showMailBcc
      this.$set(this.ticketMails, index, item)
    },
    sendMailOrAttachment (item, index) {
      if (this.$refs[`mailForm_${index}`][0].validate()) {
        if ((item.listOfFiles && item.listOfFiles.length > 0) || (item.listOfOtherFiles && item.listOfOtherFiles.length > 0)) {
          var formData = new FormData()
          var filesList = item.attachmentsFiles
          if (filesList !== null && filesList.length > 0) {
            filesList.forEach(file => {
              formData.append(file.name, file)
            })
          }
          this.sendMailLoading = true
          this.$api.execute('post', 'mailattachments/upload', formData)
            .then(response => {
              if (response.data && response.data.length > 0) {
                const attachmentIds = response.data.map(x => x._id)
                item.attachments = attachmentIds
                this.$set(this.ticketMails, index, item)
                this.sendMail(item, index)
              }
            })
        } else this.sendMail(item, index)
      }
    },
    sendMail (item, index) { // item, index
      // if (this.$refs[`mailForm_${index}`][0].validate()) {
      if (this.$refs[`mailForm_${index}`][0].validate()) {
        let body = this.$formatter.isArrayHasData(this.selectedMailItem.mailBody) ? this.selectedMailItem.mailBody : ''
        const to = this.$formatter.isArrayHasData(this.selectedMailItem.mailTo) ? this.selectedMailItem.mailTo : ''
        const cc = this.$formatter.isArrayHasData(this.selectedMailItem.mailCc) ? this.selectedMailItem.mailCc : []
        const bcc = this.$formatter.isArrayHasData(this.selectedMailItem.mailBcc) ? this.selectedMailItem.mailBcc : []
        const arrayOfEmails = [to, cc, bcc]
        if (arrayOfEmails.length > 0) {
          for (let i = 0; i < arrayOfEmails.length; i++) {
            for (let j = 0; j < arrayOfEmails[i].length; j++) {
              if (!this.emailRegex.test(arrayOfEmails[i][j])) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidEmail' })
                return false
              }
            }
          }
        }
        this.sendMailLoading = true
        body = body ? body.replace('<iframe width="100%" frameborder="0" id="userSignFooter"> </iframe>', '') : ''
        const sendObj = {
          mailid: this.selectedMailItem.mailid,
          from: this.selectedMailItem.from,
          body: body,
          to: to,
          cc: cc,
          bcc: bcc,
          attachments: this.ticketMails[index].attachments && this.ticketMails[index].attachments.length > 0 ? this.ticketMails[index].attachments : [],
          ticket_id: [this.modelObj._id]
        }
        let url = '/mails/reply'
        if (this.actionType === 3) url = '/mails/forward'
        this.$api.execute('post', url, sendObj)
          .then((response) => {
            item.listOfOtherFiles = []
            item.listOfFiles = []
            item.attachmentsFiles = []
            if (response.status === 200) {
              if (response.data) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'sentSuccess' })
                /* item.isShowMailForm = false
                this.$set(this.ticketMails, index, item) */
                this.selectedMailItem = null
                this.showReplyForwardMenu = false
                this.actionType = 0
                this.getSingleRecordHandler()
              }
            }
          })
          .finally(() => {
            this.sendMailLoading = false
          })
      }
    },
    cancelMail (item, index) {
      item.isShowMailForm = false
      this.$set(this.ticketMails, index, item)
      this.showReplyForwardMenu = false
    },
    mimeTypeOfDocument (type) {
      let icon = ''
      switch (type) {
        case '.png':
        case '.jpeg':
        case '.gif':
        case '.jpg':
        case '.jfif':
          icon = 'mdi-file-image'
          break
        case '.pdf':
          icon = { icon: 'mdi-file-pdf', color: 'red' }
          break
        case '.xlsx':
          icon = { icon: 'mdi-file-excel', color: 'green' }
          break
        case '.html':
          icon = { icon: 'mdi-language-html5', color: '' }
          break
        case '.mp4':
          icon = { icon: 'mdi-file-video', color: '' }
          break
        case '.mpeg':
          icon = 'mdi-audiobook'
          break
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
          icon = { icon: 'mdi-file-word', color: 'info' }
          break
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
          icon = { icon: 'mdi-file-excel', color: 'green' }
          break
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.presentationml.template':
        case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          icon = { icon: 'mdi-file-powerpoint', color: 'error' }
          break
        case 'application/x-rar-compressed':
        case 'application/octet-stream':
        case 'application/zip':
        case 'application/x-zip-compressed':
        case 'multipart/x-zip':
          icon = { icon: 'mdi-zip-box', color: null }
          break
        default:
          icon = { icon: 'mdi-file-cloud', color: null }
          break
      }
      return icon
    },
    downloadFile (id) {
      this.$api.execute('get', `/mailattachments/download/${id}`).then(response => {
        const filename = response.data
        window.open(`${process.env.VUE_APP_URL}files/download?fileName=${filename}`, '_blank')
      })
    },
    convertTypeHandler () {
      let queryname = ''
      const taskOrTicketInfo = this.$formatter.cloneVariable(this.modelObj)
      if (taskOrTicketInfo) {
        const filteredMail = this.$formatter.cloneVariable(this.ticketMails.find(x => x.mailid === taskOrTicketInfo.mailid))
        taskOrTicketInfo.body = filteredMail ? filteredMail.body : ''
      }
      if (taskOrTicketInfo.is_task) queryname = 'convertticket'
      else queryname = 'converttask'
      window.localStorage.setItem('taskOrTicketInfo', JSON.stringify(taskOrTicketInfo))
      this.$router.push(`/tickets/create?${queryname}=true`)
    },
    convertToSale () {
      const obj = this.$formatter.cloneVariable(this.modelObj)
      const contactArray = []
      let contact = ''
      let assignedTo = this.userDetails._id
      const saleModule = this.modulesRelated.find(x => x.name === this.SALE)
      if (obj.assignedto) {
        const splitValue = obj.assignedto.split('_')
        if (splitValue[0] !== 'group') assignedTo = splitValue[1]
      }
      const hasSelectedcontact = this.modulesRelated.find(x => x.name === 'Contact')
      if (hasSelectedcontact && hasSelectedcontact.selectedValue) {
        const foundArray = hasSelectedcontact.listOfValues.find(x => x._id === hasSelectedcontact.selectedValue)
        if (foundArray) {
          contactArray.push({
            _id: foundArray._id,
            name: foundArray.data.name
          })
          contact = hasSelectedcontact.selectedValue
        }
      }
      let companyArray = []
      if (obj.account_info && obj.account_info.length > 0) {
        companyArray = [{
          _id: obj.account_info[0]._id,
          name: obj.account_info[0].data.name
        }]
      }
      const model = {
        resposible: assignedTo,
        company: obj.account_id,
        contact,
        name: this.ticketMails[0].subject,
        companyArray,
        contactArray
      }
      if (saleModule) {
        this.$router.push(`/module/${saleModule.name}/${saleModule._id}/actions?fromticket=${encodeURIComponent(JSON.stringify(model))}`)
      }
    },
    showMailInfoEdit (item, index) {
      this.updateMailInfo = this.$formatter.cloneVariable(item)
      this.showFieldsForTask = true
    },
    updateMailValues () {
      const model = this.$formatter.cloneVariable(this.updateMailInfo)
      model.attachments = []
      this.$api.execute('post', `mails/update_task/${this.modelObj._id}`, model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'updatedSuccess' })
        this.showFieldsForTask = false
        this.getSingleRecordHandler()
      })
    },
    // loadValues () {
    //   if (this.modelObj.account_id) {
    //     this.modulesRelated.forEach((element, index) => {
    //       this.modulesRelated[index].listOfValues = []
    //       element.selectedValue = ''
    //       this.getRelatedList(element.name, index)
    //     })
    //   }
    // },
    loadValues () {
      this.modulesRelated.forEach((element, index) => {
        this.modulesRelated[index].listOfValues = []
        if (element.name === this.PROJECT) this.getProjectById(this.modelObj)
        else if (this.modelObj.account_id) {
          element.selectedValue = ''
          this.getRelatedList(element.name, index)
        }
      })
    },
    async getRelatedList (name, index) {
      await this.$api.execute('get', `moduledata/${name}/search?relation_module=${this.ACCOUNT}&relation_id=${this.modelObj.account_id}`).then(async response => {
        const result = await response.data
        if (name === this.CONTACT || name === this.PROJECT) {
          result.forEach(item => {
            item.data.name = name === this.CONTACT ? `${item.data.name || ''} ${item.data.last_name || ''}` : `${item.data.number || ''} ${item.data.name || ''}`
          })
        }
        this.$set(this.modulesRelated, index, { ...this.modulesRelated[index], listOfValues: result })
      })
    }
  },
  beforeDestroy () {
    this.$root.$off('loadComments')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('deleteSuccess')
  }
}
</script>
<style>
.custom-ckeditor-height .ck-content {
  min-height: 120px;
}
.custom_toolbar_content > .v-toolbar__content {
  padding: 0px !important;
}
.ck-focused {
  border: 1px solid #c4c4c4 !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px !important;
}
.notes-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
  position: fixed;
}
.notes-wrapper {
    min-width: 80px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-color: rgba(0, 0, 0, 0.3) !important;
    position: fixed !important;
    top: 11.3vh !important;
    right: -25px !important;
    border-radius: 8px !important;
}
.notes-wrapper--xs {
     top: 14.5vh !important;
}
.notes-wrapper--sm {
     top: 10.5vh !important;
}
.image img {
  height: 400px;
}
.v-menu__content.theme--light.menuable__content__active.custom_position_style {
    position: fixed !important;
    top: 1rem !important;
}
.noscroll{
  overflow: hidden;
}
.fullscreen{
  overflow-x: hidden !important;
}
/* .notes .v-menu__content{
  max-width: 100%;
} */
</style>
